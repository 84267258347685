<template>
  <div class="card-container text" @click="cardClick">
    <div>
      <h3>Bande-annonce :</h3>
      <div>Cliquez ici pour voir la bande-annonce.</div>
    </div>
    <div class="col-youtube-logo">
      <img src="@/assets/YouTube_logo.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TrailerCard",
  data() {
    return {
      apiKey: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      tmdbPosterUrl: "https://image.tmdb.org/t/p/w342",
      youtubeWatchUrl: "https://www.youtube.com/watch?v=",
      youtubeSearchUrl: "https://www.youtube.com/results?search_query=",
      youtubeKey: null,
      title: "",
    };
  },
  props: {
    movieId: String,
  },
  methods: {
    cardClick() {
      if (this.youtubeKey) window.open(this.youtubeWatchUrl + this.youtubeKey);
      else window.open(this.youtubeSearchUrl + this.title + "+movie+trailer");
    },
  },
  async mounted() {
    try {
      // Getting the title in case there's no trailer
      const responseDetails = await fetch(`${this.tmdbUrl}/movie/${this.movieId}?api_key=${this.apiKey}`);
      const responseDetailsData = await responseDetails.json();

      this.title = responseDetailsData.title;

      // Getting the trailer
      const responseVideos = await fetch(`${this.tmdbUrl}/movie/${this.movieId}/videos?api_key=${this.apiKey}`);
      const responseVideosData = await responseVideos.json();

      // Official trailer
      this.youtubeKey = responseVideosData.results
        .filter((video) => video.site === "YouTube" && video.official && video.type === "Trailer")
        .sort((video) => video.published_at)[0]?.key;

      // Official teaser
      if (this.youtubeKey === null) {
        this.youtubeKey = responseVideosData.results
          .filter((video) => video.site === "YouTube" && video.official && video.type === "Teaser")
          .sort((video) => video.published_at)[0]?.key;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.card-container {
  line-height: var(--line-height);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .card-container {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}

.col-youtube-logo {
  width: 17%;
}

.col-youtube-logo > img {
  width: 100%;
}

h3 {
  line-height: var(--line-height);
  margin-top: 0;
}

p {
  margin: 0 0 0.5rem 0;
}
</style>
