import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";
import { isUserAdminInList, isUserMemberInList } from "@/firebase";

import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Search from "../views/Search.vue";
import ListCreateOrEdit from "../views/ListCreateOrEdit.vue";
import Invitations from "../views/Invitations.vue";
import ListDetails from "../views/ListDetails.vue";
import MovieDetails from "../views/MovieDetails.vue";
import StartVote from "../views/StartVote.vue";
import ListVote from "../views/ListVote.vue";
import VoteHistory from "../views/VoteHistory.vue";
import SignIn from "../views/SignIn.vue";
import Register from "../views/Register.vue";
import AddToList from "../views/AddToList.vue";
import ExploreMovies from "../views/ExploreMovies.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      menuButton: "list",
      pageTitle: "Accueil",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      menuButton: "profile",
      pageTitle: "Votre compte",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      requiresAuth: true,
      menuButton: "search",
      pageTitle: "Rechercher",
    },
  },
  {
    path: "/list-create",
    name: "ListCreate",
    component: ListCreateOrEdit,
    meta: {
      requiresAuth: true,
      pageTitle: "Nouvelle liste",
    },
  },
  {
    path: "/list-edit/:id",
    name: "ListEdit",
    component: ListCreateOrEdit,
    meta: {
      requiresAuth: true,
      pageTitle: "Modifier une liste",
    },
  },
  {
    path: "/list-details/:id",
    name: "ListDetails",
    component: ListDetails,
    meta: {
      requiresAuth: true,
      pageTitle: "Détails d'une liste",
    },
  },
  {
    path: "/invitations",
    name: "Invitations",
    component: Invitations,
    meta: {
      requiresAuth: true,
      pageTitle: "Invitations",
    },
  },
  {
    path: "/movie-details/:movieId/:listId",
    name: "MovieDetails",
    component: MovieDetails,
    meta: {
      requiresAuth: true,
      pageTitle: "Détails d'un film",
    },
  },
  {
    path: "/explore-movies/:jobType/:personId",
    name: "ExploreMovies",
    component: ExploreMovies,
    meta: {
      requiresAuth: true,
      pageTitle: "Découvrir des films",
    },
  },
  {
    path: "/add-to-list/:id",
    name: "AddToList",
    component: AddToList,
    meta: {
      requiresAuth: true,
      pageTitle: "Ajouter à une liste",
    },
  },
  {
    path: "/start-vote/:id",
    name: "StartVote",
    component: StartVote,
    meta: {
      requiresAuth: true,
      pageTitle: "Démarrer un vote",
    },
  },
  {
    path: "/vote/:id",
    name: "ListVote",
    component: ListVote,
    meta: {
      requiresAuth: true,
      pageTitle: "Voter",
    },
  },
  {
    path: "/vote-results/:id",
    name: "VoteHistory",
    component: VoteHistory,
    meta: {
      requiresAuth: true,
      pageTitle: "Historique des résultats",
    },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: {
      requiresAuth: false,
      menuButton: "profile",
      pageTitle: "Connexion",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      requiresAuth: false,
      menuButton: "profile",
      pageTitle: "Nouveau compte",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function updateMenuButtons(btnToAddClassTo, btnsToRemoveClassFrom) {
  if (btnToAddClassTo !== null) btnToAddClassTo.classList.add("col-selected");
  btnsToRemoveClassFrom.forEach((btn) => btn.classList.remove("col-selected"));
}

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  document.title = "MovieVote | " + to.meta.pageTitle;

  // Change the selected menu button
  const menuButton = to.matched.some((record) => record.meta.menuButton) ? to.meta.menuButton : "";
  let btnSearch = document.getElementById("btnSearch");
  let btnList = document.getElementById("btnList");
  let btnProfile = document.getElementById("btnProfile");

  switch (menuButton) {
    case "search":
      updateMenuButtons(btnSearch, [btnList, btnProfile]);
      break;
    case "list":
      updateMenuButtons(btnList, [btnSearch, btnProfile]);
      break;
    case "profile":
      updateMenuButtons(btnProfile, [btnSearch, btnList]);
      break;
    default:
      updateMenuButtons(null, [btnSearch, btnList, btnProfile]);
      break;
  }

  // If the route requires authentication, check if the user is logged in
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const user = getAuth().currentUser;

  if (requiresAuth && !user) {
    updateMenuButtons(btnProfile, [btnSearch, btnList]);
    next("sign-in");
  } else {
    // If the route requires permission, check if the user has the permission
    switch (to.name) {
      case "ListEdit":
        {
          const isAdmin = await isUserAdminInList(user.uid, to.params.id);
          if (!isAdmin) next("/");
          else next();
        }
        break;
      case "ListDetails":
      case "StartVote":
      case "ListVote":
      case "VoteResults":
        {
          const isMember = await isUserMemberInList(user.uid, to.params.id);
          if (!isMember) next("/");
          else next();
        }
        break;
      case "MovieDetails":
        {
          if (to.params.listId != "no-list") {
            const isMember = await isUserMemberInList(user.uid, to.params.listId);
            if (!isMember) next("/");
            else next();
          } else next();
        }
        break;
      default:
        next();
        break;
    }
  }
});

export default router;
