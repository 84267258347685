<template>
  <h2 class="title">Actions rapides</h2>
  <IconButton text="Nouvelle liste" iconClass="fa-plus" @btnClick="newList" />
  <IconButton :text="'Invitations (' + (userInvites ? userInvites.length : 0) + ')'" iconClass="fa-envelope" @btnClick="showInvitations" />
  <h2 class="title">Mes listes</h2>
  <div class="text" v-if="userLists != null && userLists.length > 0">
    <div v-for="list in userLists" :key="list.id">
      <ListCard :list="list" @card-click="listClick(list.id)" />
    </div>
  </div>
  <div v-else class="text">Vous n'avez aucune liste pour le moment.</div>
</template>

<script>
import IconButton from "@/components/IconButton";
import ListCard from "@/components/ListCard";
import { getUserLists, getUserInvites } from "@/firebase";
import { getAuth } from "firebase/auth";

export default {
  name: "Home",
  data() {
    return {
      hasLists: false,
      userLists: null,
      userInvites: null,
    };
  },
  components: {
    IconButton,
    ListCard,
  },
  methods: {
    newList() {
      this.$router.push({ name: "ListCreate" });
    },
    showInvitations() {
      this.$router.push({ name: "Invitations" });
    },
    listClick(id) {
      this.$router.push({ name: "ListDetails", params: { id: id } });
    },
  },
  async mounted() {
    const auth = getAuth();
    const user = auth.currentUser;
    this.userLists = getUserLists(user.uid);
    this.userInvites = getUserInvites(user.uid);
  },
};
</script>
