<template>
  <div v-if="list">
    <h2 class="title">Voter — {{ this.list.name }}</h2>
    <p v-if="this.list.vote?.nbVotes && this.list.vote.nbVotes > 1" class="text">
      Sélectionnez jusqu'à {{ this.list.vote.nbVotes }} films que vous voulez écouter :
    </p>
    <p v-else class="text">Sélectionnez un film que vous voulez écouter :</p>
    <div class="movie-cards-container">
      <MovieCard
        v-for="movie in moviesList"
        :key="movie"
        :movieId="movie"
        :listId="id"
        :voting="true"
        :votes="numberOfVotesForMovie(movie)"
        :showVotes="true"
        :canAddVotes="this.list.vote?.nbVotes && this.votes.length < this.list.vote.nbVotes"
        :alreadySelected="list.vote.votes[userId]?.includes(movie)"
        @voteStatusChanged="updateVoteStatus(movie, $event)"
      />
    </div>
  </div>
  <IconButton text="Confirmer mon vote!" iconClass="fa-check" @btnClick="confirmVote" />
  <IconButton text="Annuler" iconClass="fa-times" bgColor="#b83939" @btnClick="backToList" />
  <ArrowDown />
</template>

<script>
import MovieCard from "@/components/MovieCard";
import IconButton from "@/components/IconButton";
import ArrowDown from "@/components/ArrowDown";
import { getListRealtime, updateList } from "@/firebase";
import { getAuth } from "firebase/auth";

export default {
  name: "ListVote",
  data() {
    return {
      id: null,
      list: null,
      votes: [],
      userId: null,
    };
  },
  components: {
    MovieCard,
    IconButton,
    ArrowDown,
  },
  methods: {
    updateVoteStatus(movieId, status) {
      if (status) {
        this.votes.push(movieId);
      } else {
        this.votes = this.votes.filter((vote) => vote !== movieId);
      }
    },
    confirmVote() {
      if (
        this.votes.length < this.list.vote.nbVotes &&
        !confirm("Vous avez sélectionné moins de films que le nombre maximal de votes. Voulez-vous tout de même confirmer votre vote?")
      ) {
        return;
      }

      const userId = getAuth().currentUser.uid;
      this.list.vote.votes[userId] = this.votes;
      updateList(this.list);
      this.backToList();
    },
    backToList() {
      this.$router.replace({
        name: "ListDetails",
        params: { id: this.id },
      });
    },
    numberOfVotesForMovie(movieId) {
      let movies = [];
      Object.keys(this.list.vote.votes).forEach((userId) => {
        movies = movies.concat(this.list.vote.votes[userId]);
      });

      return movies.filter((movie) => movie === movieId).length;
    },
  },
  computed: {
    lastWinners() {
      if (!this.list.history || this.list.history.length === 0) return [];
      const sortedHistory = [...this.list.history];
      sortedHistory.sort((a, b) => b.date.localeCompare(a.date));
      return sortedHistory[0].winners ?? [];
    },
    moviesList() {
      return this.list.vote?.revote ? this.lastWinners : this.list.movies;
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    const list = getListRealtime(this.id);
    this.list = list;

    const auth = getAuth();
    const user = auth.currentUser;
    this.userId = user.uid;
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .movie-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.error {
  margin-bottom: 1rem;
  line-height: var(--line-height);
}

.text {
  line-height: var(--line-height);
}
</style>
