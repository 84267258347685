import { ref, onUnmounted } from "vue";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, setDoc, addDoc, onSnapshot, doc, deleteDoc, getDoc, getDocs, updateDoc, where, query } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAzSPWYvAbfR5aW_D-fy9STrf8nLv6CHc",
  authDomain: "vue-movie-vote.firebaseapp.com",
  projectId: "vue-movie-vote",
  storageBucket: "vue-movie-vote.appspot.com",
  messagingSenderId: "703043576333",
  appId: "1:703043576333:web:6efd56fab229539769eb44",
};

initializeApp(firebaseConfig);
const db = getFirestore();

// Lists management
const listsCollection = collection(db, "lists");

export const createList = (list) => {
  return addDoc(listsCollection, list);
};

export const getListRealtime = (id) => {
  const list = ref([]);
  const q = query(listsCollection, where("__name__", "==", id));

  const close = onSnapshot(q, (snapshot) => {
    const doc = snapshot.docs[0];
    list.value = {
      id: doc.id,
      ...doc.data(),
    };
  });
  onUnmounted(close);
  return list;
};

export const getList = async (id) => {
  const list = await getDoc(doc(listsCollection, id));
  return list.exists ? list.data() : null;
};

export const updateList = (list) => {
  return updateDoc(doc(listsCollection, list.id), list);
};

export const deleteList = (id) => {
  return deleteDoc(doc(listsCollection, id));
};

export const getUserLists = (userId) => {
  const lists = ref([]);
  const q = query(listsCollection, where("members", "array-contains", userId));

  const close = onSnapshot(q, (snapshot) => {
    lists.value = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  });
  onUnmounted(close);
  return lists;
};

export const isUserAdminInList = async (userId, listId) => {
  let isAdmin = false;
  const q = query(listsCollection, where("administrators", "array-contains", userId));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((list) => {
    if (list.id === listId) isAdmin = true;
  });

  return isAdmin;
};

export const isUserMemberInList = async (userId, listId) => {
  let isMember = false;
  const q = query(listsCollection, where("members", "array-contains", userId));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((list) => {
    if (list.id === listId) isMember = true;
  });

  return isMember;
};

// Invites management
const invitesCollection = collection(db, "invites");

export const createInvite = (invite) => {
  return addDoc(invitesCollection, invite);
};

export const getInvite = async (id) => {
  const invite = await getDoc(doc(invitesCollection, id));
  return invite.exists ? invite.data() : null;
};

export const deleteInvite = (id) => {
  return deleteDoc(doc(invitesCollection, id));
};

export const getUserInvites = (userId) => {
  const invites = ref([]);
  const q = query(invitesCollection, where("to", "==", userId));

  const close = onSnapshot(q, (snapshot) => {
    invites.value = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  });
  onUnmounted(close);
  return invites;
};

export const getUserSentInvites = (userId) => {
  const invites = ref([]);
  const q = query(invitesCollection, where("from", "==", userId));

  const close = onSnapshot(q, (snapshot) => {
    invites.value = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  });
  onUnmounted(close);
  return invites;
};

export const deleteInvitesForList = async (listId) => {
  const q = query(invitesCollection, where("list", "==", listId));
  const snapshot = await getDocs(q);
  snapshot.docs.forEach((document) => {
    deleteDoc(doc(invitesCollection, document.id));
  });
};

export const deleteInvitesForUserForList = async (userId, listId) => {
  const q = query(invitesCollection, where("list", "==", listId), where("to", "==", userId));
  const snapshot = await getDocs(q);
  snapshot.docs.forEach((document) => {
    deleteDoc(doc(invitesCollection, document.id));
  });
};

// Users management
const usersCollection = collection(db, "users");

export const createUser = (user, userId) => {
  return setDoc(doc(usersCollection, userId), user);
};

export const getUser = async (id) => {
  const user = await getDoc(doc(usersCollection, id));
  return user.exists ? user.data() : null;
};

export const getUserByUsername = async (username) => {
  const q = query(usersCollection, where("username", "==", username));
  const querySnapshot = await getDocs(q);

  const doc = querySnapshot.docs[0];
  const user =
    querySnapshot.docs.length > 0
      ? {
          id: doc.id,
          ...doc.data(),
        }
      : null;

  return user;
};

export const getUserByEmail = async (email) => {
  const q = query(usersCollection, where("email", "==", email));
  const querySnapshot = await getDocs(q);

  const doc = querySnapshot.docs[0];
  const user =
    querySnapshot.docs.length > 0
      ? {
          id: doc.id,
          ...doc.data(),
        }
      : null;

  return user;
};

export const deleteUser = (id) => {
  return deleteDoc(doc(usersCollection, id));
};

export const checkIfUsernameExists = async (username) => {
  let count = 0;
  const q = query(usersCollection, where("username", "==", username));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(() => {
    count++;
  });

  return count > 0;
};
