<template>
  <div id="empty"></div>
  <div id="menu-bar-container">
    <div id="btnSearch" class="col" @click="btnSearchClick">
      <i class="fas fa-search"></i>
    </div>
    <div id="btnList" class="col col-selected" @click="btnListClick">
      <div class="sep sep-left"></div>
      <i class="fas fa-list-ul"></i>
      <div class="sep sep-right"></div>
    </div>
    <div id="btnProfile" class="col" @click="btnProfileClick">
      <i class="fas fa-user"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnSearch: "",
      btnList: "",
      btnProfile: "",
    };
  },
  methods: {
    btnSearchClick() {
      this.$router.push({ name: "Search" });
    },
    btnListClick() {
      this.$router.push({ name: "Home" });
    },
    btnProfileClick() {
      this.$router.push({ name: "Profile" });
    },
  },
};
</script>

<style scoped>
#empty {
  height: 3.4rem;
}

#menu-bar-container {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--light-grey);
  text-align: center;
  border-top: var(--border);
}

.fas {
  color: var(--grey);
  font-size: 1.75rem;
}

.col {
  position: relative;
  padding: 1.25rem 0.75rem;
  flex-grow: 1;
  cursor: pointer;
  transition-duration: var(--transition-duration);
}

.col:hover {
  background-color: var(--green-hover);
}

.col:hover > .fas {
  color: var(--light-grey);
}

.col-selected {
  background-color: var(--green);
}

.col-selected > .fas {
  color: var(--light-grey);
}

.sep {
  height: 70%;
  position: absolute;
  top: 15%;
  border-radius: 30%;
}

.sep-left {
  border-left: 2px solid var(--grey);
  left: -0.5px;
}

.sep-right {
  border-right: 2px solid var(--grey);
  right: -0.5px;
}
</style>
