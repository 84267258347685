<template>
  <h2 class="title">Vos invitations</h2>
  <div v-if="userInvites != null && userInvites.length > 0" class="text">
    <div v-for="invite in userInvites" :key="invite.id">
      <InviteCard :invite="invite" />
    </div>
  </div>
  <div v-else class="text error">Vous n'avez aucune invitation pour le moment.</div>
</template>

<script>
import InviteCard from "@/components/InviteCard";
import { getUserInvites } from "@/firebase";
import { getAuth } from "firebase/auth";

export default {
  name: "Invitations",
  data() {
    return {
      userInvites: [],
    };
  },
  components: {
    InviteCard,
  },
  async mounted() {
    const auth = getAuth();
    const user = auth.currentUser;
    this.userInvites = getUserInvites(user.uid);
  },
};
</script>

<style scoped>
.text {
  line-height: var(--line-height);
}

.error {
  margin-bottom: 1rem;
}
</style>
