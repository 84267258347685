<template>
  <form @submit.prevent="formSubmit">
    <h2 class="title">Connectez-vous à un compte</h2>
    <TextInput type="text" v-model="usernameOrEmail" placeholder="Entrez votre courriel / nom d'utilisateur" />
    <TextInput type="password" v-model="password" placeholder="Entrez votre mot de passe" />
    <IconButton text="Me connecter" iconClass="fa-sign-in-alt" />
    <div v-if="error" class="error text">{{ error }}</div>
    <div class="text">
      Pas encore de compte?
      <router-link to="/register">Créez-en un!</router-link>
    </div>
  </form>
</template>

<script>
import IconButton from "@/components/IconButton";
import TextInput from "@/components/TextInput";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getUserByUsername } from "@/firebase";

export default {
  name: "SignIn",
  data() {
    return {
      usernameOrEmail: "",
      password: "",
      error: "",
    };
  },
  components: {
    IconButton,
    TextInput,
  },
  methods: {
    async formSubmit() {
      const auth = getAuth();
      const userInfosFromUsername = await getUserByUsername(this.usernameOrEmail);

      const email = userInfosFromUsername ? userInfosFromUsername.email : this.usernameOrEmail;

      signInWithEmailAndPassword(auth, email, this.password)
        .then(() => {
          // User signed in successfully
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          // Descriptive UI errors
          switch (error.code) {
            case "auth/too-many-requests":
              this.error =
                "L'accès à ce compte a été temporairement suspendu suite à un trop grand nombre d'échecs de connexion. Veuillez réessayer plus tard.";
              break;
            case "auth/wrong-password":
              this.error = "La combinaison courriel / nom d'utilisateur et mot de passe n'est pas valide.";
              break;
            case "auth/user-not-found":
            case "auth/invalid-email":
              this.error = "Ce courriel / nom d'utilisateur n'est pas valide.";
              break;
            default:
              this.error = "Une erreur est survenue. Veuillez vérifier tous les champs et réessayer.";
              break;
          }

          // Empty password input
          this.password = "";
        });
    },
  },
};
</script>

<style scoped>
.error {
  color: var(--red);
  margin-bottom: 1rem;
  line-height: var(--line-height);
}
</style>
