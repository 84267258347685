<template>
  <div class="card-container" :class="{ disabled: (hasMovie || list.vote.ongoing) && canAdd }" @click="cardClick">
    <div class="list-text">
      <strong>
        {{ this.list.name }}
      </strong>
      <div v-if="canAdd" class="list-error">
        <span v-if="hasMovie">Ce film a été ajouté à cette liste.</span>
        <span v-if="list.vote.ongoing && !hasMovie"> Vous ne pouvez pas ajouter de film durant un vote. </span>
      </div>
    </div>
    <div class="list-pills">
      <div class="pill">
        {{ this.list.movies.length }}
      </div>
      <div class="pill" :title="isOwner ? 'Vous êtes propriétaire de cette liste' : 'Vous avez été invité dans cette liste'">
        <span :class="isOwner ? 'fa fa-user' : 'fa fa-envelope'"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";

export default {
  name: "ListCard",
  data() {
    return {
      hasMovie: false,
      isOwner: false,
    };
  },
  props: {
    list: Object,
    movieId: String,
    canAdd: Boolean,
  },
  methods: {
    cardClick() {
      this.$emit("card-click");
      if (!this.hasMovie && this.movieId != null && !this.list.vote.ongoing) {
        this.hasMovie = true;
      }
    },
  },
  mounted() {
    this.hasMovie = this.list.movies.includes(this.movieId);

    const auth = getAuth();
    const user = auth.currentUser;
    const isOwner = this.list.owner === user.uid;
    this.isOwner = isOwner;
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.list-pills {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pill {
  text-align: center;
  height: 2rem;
  width: 2rem;
  line-height: 2;
  background-color: var(--green);
  border-radius: 50%;
  color: white;
}

.disabled {
  background-color: lightgrey;
  cursor: auto;
}

.list-text {
  line-height: 1;
}

.list-error {
  font-size: 0.9rem;
  margin-top: 0.1rem;
  line-height: 0.9;
}
</style>
