<template>
  <div id="arrow-down" @click="scrollToBottom">
    <i class="fa fa-arrow-down"></i>
  </div>
</template>

<script>
export default {
  name: "ArrowDown",
  methods: {
    scrollToBottom() {
      const footer = document.getElementById("footer-container");
      footer.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#arrow-down {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  z-index: 1;
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: var(--green);
  text-align: center;
  transition-duration: var(--transition-duration);
  border: var(--border);
}

#arrow-down:hover {
  filter: brightness(140%);
}

#arrow-down i {
  font-size: 2rem;
  color: white;
  line-height: 4rem;
}
</style>
