<template>
  <input :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder" />
</template>

<script>
export default {
  name: "TextInput",
  props: {
    placeholder: {
      type: String,
      default: "Text input",
    },
    modelValue: String,
    type: String,
  },
};
</script>

<style scoped>
input {
  width: 100%;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: var(--font-family-texts);
  margin-bottom: 1rem;
  border: 2px solid var(--grey);
  border-radius: var(--border-radius);
}

input[type="text"]:focus {
  outline: none;
}
</style>
