<template>
  <div id="footer-container">
    <div class="row">
      <div class="text">
        Les données des films sont fournies par l'API de
        <i>The Movie Database</i>. MovieVote n'est pas affilié avec <i>The Movie Database</i> d'aucune manière.
      </div>
      <a href="https://www.themoviedb.org/" target="_blank" class="api-logo">
        <img src="@/assets/TMDB_logo_alt.svg" alt="TMDB Logo" />
      </a>
    </div>
    <br />
    <div class="row">
      <div class="text">
        La disponibilité des films est fournie par l'API de
        <i>JustWatch</i>. MovieVote n'est pas affilié avec <i>JustWatch</i> d'aucune manière.
      </div>
      <a href="https://www.justwatch.com/" target="_blank" class="api-logo">
        <img src="@/assets/JustWatch_logo.png" class="api-logo" />
      </a>
    </div>
    <br />
    <div class="text center">Copyright MovieVote &copy; 2022</div>
  </div>
  <br /><br />
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
#footer-container {
  width: calc(100% - 2rem);
  background-color: var(--light-grey);
  padding: 1rem;
  box-sizing: border-box;
  margin: 0 1rem;
  border-radius: var(--border-radius);
}

@media (min-width: 750px) {
  #footer-container {
    margin: 1rem calc(20% + 1rem);
    width: calc(60% - 2rem);
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: var(--line-height);
  gap: 1.5rem;
  width: 100%;
}

.api-logo {
  display: block;
}

.api-logo > img {
  width: 125px;
}

.center {
  text-align: center;
}
</style>
