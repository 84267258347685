import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBAzSPWYvAbfR5aW_D-fy9STrf8nLv6CHc",
  authDomain: "vue-movie-vote.firebaseapp.com",
  projectId: "vue-movie-vote",
  storageBucket: "vue-movie-vote.appspot.com",
  messagingSenderId: "703043576333",
  appId: "1:703043576333:web:6efd56fab229539769eb44",
};

initializeApp(firebaseConfig);

let app;

// Fix for refresh glitch
const auth = getAuth();
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App).use(router).mount("#app");
  }
});
