<template>
  <div class="card-container" :class="selectedForVote && 'selected-for-vote'" @click="cardClick">
    <div class="flex-container">
      <div class="col-poster">
        <img v-if="posterUrl" :src="posterUrl" class="poster" />
        <img v-else src="@/assets/ImageNotFound.jpg" class="poster" />
      </div>
      <div class="col-infos text">
        <h3 v-if="title" class="movie-title">
          {{ this.title }}
          <span v-if="releaseYear"> ({{ this.releaseYear }}) </span>
        </h3>
        <div v-if="rating && rating != 0" class="imdb-rating">
          <span v-for="i in 5" :key="i" class="stars">
            <i v-if="i <= starsRating" class="fa fa-star"></i>
            <i v-if="i + 0.5 == starsRating" class="fas fa-star-half-alt"></i>
            <i v-if="i - 0.5 > starsRating" class="far fa-star"></i>
          </span>
          ({{ this.rating }}/10)
        </div>
        <span v-if="genres.length > 0">
          {{ this.genres }}
        </span>
        <span v-if="genres.length > 0 && duration != null"> – </span>
        <span v-if="duration != null">
          {{ this.duration }}
        </span>
      </div>
    </div>
    <div v-if="showVotes" class="votes">{{ this.votes }} vote{{ this.votes > 1 ? "s" : "" }}</div>
  </div>
</template>

<script>
export default {
  name: "MovieCard",
  data() {
    return {
      apiKeyTmdb: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      tmdbPosterUrl: "https://image.tmdb.org/t/p/w342",
      apiKeyOmdb: "f78ba769",
      omdbUrl: "https://www.omdbapi.com",
      title: "",
      releaseYear: "",
      posterUrl: null,
      rating: null,
      starsRating: null,
      genres: [],
      duration: null,
      selectedForVote: false,
    };
  },
  props: {
    movieId: String,
    listId: String,
    voting: Boolean,
    canAddVotes: Boolean,
    alreadySelected: Boolean,
    votes: Number,
    showVotes: Boolean,
  },
  methods: {
    cardClick() {
      if (this.voting) {
        if (this.selectedForVote || (!this.selectedForVote && this.canAddVotes)) {
          this.selectedForVote = !this.selectedForVote;
          this.$emit("voteStatusChanged", this.selectedForVote);
        }
      } else {
        this.$router.push({
          name: "MovieDetails",
          params: {
            movieId: this.movieId,
            listId: this.listId ? this.listId : "no-list",
          },
        });
      }
    },
  },
  async mounted() {
    try {
      // TMDB movie details
      const responseEn = await fetch(`${this.tmdbUrl}/movie/${this.movieId}?api_key=${this.apiKeyTmdb}`);
      const responseEnData = await responseEn.json();

      const responseFr = await fetch(`${this.tmdbUrl}/movie/${this.movieId}?language=fr&api_key=${this.apiKeyTmdb}`);
      const responseFrData = await responseFr.json();

      this.title = responseEnData.title;

      const releaseDate = new Date(responseFrData.release_date);
      this.releaseYear = releaseDate.getFullYear();

      if (responseEnData.poster_path !== null) this.posterUrl = this.tmdbPosterUrl + responseEnData.poster_path;

      this.genres = responseFrData.genres.map((genre) => genre.name).join(", ");

      const runtime = responseEnData.runtime;
      if (runtime !== 0) {
        const hours = Math.floor(runtime / 60);
        let minutes = (runtime % 60).toString();
        minutes = minutes.padStart(2, "0");
        this.duration = hours + "h" + minutes;
      }

      // OMDB movie details
      const responseOMDB = await fetch(`${this.omdbUrl}/?i=${responseEnData.imdb_id}&apikey=${this.apiKeyOmdb}`);
      const responseOMDBData = await responseOMDB.json();

      // Try to find a rating
      try {
        if (responseOMDBData.Ratings != null) {
          this.rating = responseOMDBData.Ratings.find((rating) => rating.Source === "Internet Movie Database")?.Value;
        }

        if (this.rating == null) {
          this.rating = responseEnData.vote_average;
        }
      } catch (error) {
        console.log(error);
      }

      if (this.rating != null) {
        this.rating = this.rating.toString().replace("/10", "");
        this.rating = Number(this.rating).toFixed(1);

        // Convert the rating to stars
        this.starsRating = (Math.round(this.rating) / 2).toFixed(1);
      }

      // Check if the user has already voted for this movie
      if (this.voting && this.alreadySelected) {
        this.cardClick();
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.card-container {
  cursor: pointer;
}

.flex-container {
  display: flex;
}

.selected-for-vote {
  border: 2px solid var(--green);
  background-color: var(--very-light-green);
}

@media (min-width: 1200px) {
  .card-container {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}

.col-poster {
  width: 40%;
}

.col-infos {
  width: 60%;
  padding-left: 0.75rem;
  line-height: 1.5;
}

.poster {
  width: 100%;
  border-radius: var(--border-radius);
  display: block;
}

.movie-title {
  margin: 0 0 0.5rem 0;
}

.imdb-rating {
  margin: 0 0 0.5rem 0;
}

.stars {
  color: #daa520;
}

.votes {
  background-color: var(--green);
  border-radius: var(--border-radius);
  height: 2rem;
  width: 100%;
  margin-top: 0.75rem;
  color: white;
  text-align: center;
}
</style>
