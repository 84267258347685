<template>
  <h2 class="title">Recherchez un film...</h2>
  <TextInput type="text" v-model="movieTitle" placeholder="Entrez le titre d'un film" @keyup.enter="search" />
  <IconButton text="Lancer la recherche" iconClass="fa-search" @btnClick="search" />
  <div v-if="error" class="error text">{{ error }}</div>
  <div class="movie-cards-container">
    <MovieCard v-for="movie in searchResults.results" :key="movie.id" :movieId="movie.id.toString()" />
  </div>
  <h2 class="title">...ou découvrez les nouveautés</h2>
  <div class="movie-cards-container">
    <MovieCard v-for="movie in nowPlaying.results" :key="movie.id" :movieId="movie.id.toString()" />
  </div>
</template>

<script>
import TextInput from "@/components/TextInput";
import IconButton from "@/components/IconButton";
import MovieCard from "@/components/MovieCard";

export default {
  name: "Search",
  data() {
    return {
      apiKey: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      movieTitle: "",
      searchResults: {},
      nowPlaying: {},
      error: "",
    };
  },
  components: {
    TextInput,
    IconButton,
    MovieCard,
  },
  methods: {
    async search() {
      try {
        const response = await fetch(`${this.tmdbUrl}/search/movie?query=${this.movieTitle}&api_key=${this.apiKey}`);
        const responseData = await response.json();
        this.searchResults = responseData;
        if (!this.searchResults.results || this.searchResults.results.length === 0) this.error = "Aucun résultat ne correspond à votre recherche.";
        else this.error = "";
      } catch (error) {
        this.error = error;
      }
    },
    async getNowPlaying() {
      try {
        const response = await fetch(`${this.tmdbUrl}/movie/now_playing?api_key=${this.apiKey}`);
        const responseData = await response.json();
        this.nowPlaying = responseData;
      } catch (error) {
        this.error = error;
      }
    },
  },
  beforeMount() {
    this.getNowPlaying();
  },
};
</script>

<style scoped>
.error {
  color: var(--red);
  margin-bottom: 1rem;
  line-height: var(--line-height);
}

@media (min-width: 1200px) {
  .movie-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
