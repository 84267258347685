<template>
  <Header />
  <div id="page-container">
    <router-view />
  </div>
  <Footer />
  <MenuBar />
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MenuBar from "@/components/MenuBar";

export default {
  components: {
    Header,
    Footer,
    MenuBar,
  },
};
</script>

<style>
:root {
  --dark-green: #00120b;
  --green: #35605a;
  --light-green: #31e981;
  --very-light-green: #d5efec;
  --green-hover: #4b867e;
  --grey: #6b818c;
  --light-grey: #d8e4ff;
  --red: #b83939;

  --border: 2px solid var(--grey);
  --border-radius: 10px;

  --transition-duration: 0.2s;

  --font-family-titles: "Dosis";
  --font-family-texts: "Cairo";

  --line-height: 1.2;
}

body {
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#page-container {
  padding: 1rem;
}

@media (min-width: 750px) {
  #page-container {
    margin: 0 20%;
  }
}

.title {
  font-family: var(--font-family-titles);
  color: var(--grey);
}

.text {
  font-family: var(--font-family-texts);
  color: var(--grey);
}

a {
  color: var(--green);
}

.card-container {
  width: 100%;
  padding: 0.75rem;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: var(--font-family-texts);
  margin-bottom: 1rem;
  border: var(--border);
  border-radius: var(--border-radius);
}
</style>
