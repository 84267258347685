<template>
  <h2 class="title">Films de {{ personName }} {{ jobType !== "" ? `(${jobType})` : "" }}</h2>
  <div v-if="error" class="error text">{{ error }}</div>
  <div class="movie-cards-container">
    <MovieCard v-for="movie in moviesToDisplay" :key="movie.id" :movieId="movie.id.toString()" />
  </div>
  <IconButton v-if="movies && moviesToLoad < movies.length" text="Charger plus de films" iconClass="fa-angle-down" @btnClick="loadMore" />
</template>

<script>
import MovieCard from "@/components/MovieCard";
import IconButton from "@/components/IconButton";

export default {
  name: "ExploreMovies",
  data() {
    return {
      apiKey: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      movies: [],
      moviesToLoad: 10,
      personName: "",
      error: "",
    };
  },
  components: {
    MovieCard,
    IconButton,
  },
  methods: {
    async getMoviesForPerson() {
      try {
        const response = await fetch(`${this.tmdbUrl}/person/${this.$route.params.personId}?api_key=${this.apiKey}&append_to_response=movie_credits`);
        const responseData = await response.json();
        this.personName = responseData.name;
        if (this.$route.params.jobType === "actor") this.movies = responseData.movie_credits.cast;
        if (this.$route.params.jobType === "director") this.movies = responseData.movie_credits.crew.filter((member) => member.job === "Director");
      } catch (error) {
        this.error = error;
      }
    },
    loadMore() {
      this.moviesToLoad += 10;
    },
  },
  computed: {
    moviesToDisplay() {
      if (!this.movies || this.movies.length === 0) return [];
      const moviesToDisplay = [...this.movies];
      moviesToDisplay.sort((a, b) => b.release_date.localeCompare(a.release_date));
      return moviesToDisplay.slice(0, this.moviesToLoad);
    },
    jobType() {
      switch (this.$route.params.jobType) {
        case "actor":
          return "acteur";
        case "director":
          return "directeur";
        default:
          return "";
      }
    },
  },
  beforeMount() {
    this.getMoviesForPerson();
  },
};
</script>

<style scoped>
.error {
  color: var(--red);
  margin-bottom: 1rem;
  line-height: var(--line-height);
}

@media (min-width: 1200px) {
  .movie-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
