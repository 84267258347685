<template>
  <div class="card-container" :class="{ 'has-movie': hasMovie }" @click="cardClick">
    <div class="text invite-text">
      <strong>{{ this.senderUsername }} </strong>
      <span> vous invite à rejoindre la liste </span>
      <strong> {{ this.listName }}</strong>
      <span v-if="invite.type === 'administrator'"> en tant qu'administrateur</span>
    </div>
    <div class="btns-container">
      <i class="fas fa-check-circle btn-accept" @click="confirmInvite(true)"></i>&nbsp;
      <i class="fas fa-times-circle btn-refuse" @click="confirmInvite(false)"></i>
    </div>
  </div>
</template>

<script>
import { getList, getUser, updateList, deleteInvite } from "@/firebase.js";
import { getAuth } from "firebase/auth";

export default {
  name: "InviteCard",
  data() {
    return {
      senderUsername: null,
      listName: null,
    };
  },
  props: {
    invite: Object,
  },
  methods: {
    async confirmInvite(accepted) {
      const auth = getAuth();
      const user = auth.currentUser;

      let list = await getList(this.invite.list);

      if (this.invite.type === "administrator") {
        if (accepted) list.administrators.push(user.uid);
        let idx = list.invitedAdministrators.findIndex((a) => a === user.uid);
        if (idx > -1) list.invitedAdministrators.splice(idx, 1);
      }
      if (accepted) list.members.push(user.uid);
      let idx = list.invitedMembers.findIndex((m) => m === user.uid);
      if (idx > -1) list.invitedMembers.splice(idx, 1);

      list.id = this.invite.list;
      updateList(list);
      deleteInvite(this.invite.id);
    },
  },
  async mounted() {
    const sender = await getUser(this.invite.from);
    this.senderUsername = sender.username;
    const list = await getList(this.invite.list);
    this.listName = list.name;
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
}

.btns-container {
  flex-direction: row;
  min-width: 4.5rem;
  margin-left: 0.75rem;
}

.btn-accept {
  color: var(--green);
  cursor: pointer;
  font-size: 2rem;
}

.btn-refuse {
  color: var(--red);
  cursor: pointer;
  font-size: 2rem;
}
</style>
