<template>
  <div v-if="list">
    <h2 class="title">Historique des résultats — {{ this.list.name }}</h2>
    <select name="months" id="months" v-model="selectedMonth">
      <option v-for="month in getMonths()" :key="month" :value="month">
        {{ month }}
      </option>
    </select>
    <div v-if="filteredHistory && filteredHistory.length > 0">
      <div v-for="(item, index) in filteredHistory" :key="item">
        <h3 class="text">{{ convertToDateString(item.date) + (isLatestVote(index) ? " (dernier vote)" : "") }}</h3>
        <div class="movie-cards-container">
          <MovieCard
            v-for="movie in item.winners"
            :key="movie"
            :movieId="movie"
            :listId="id"
            :votes="list.vote.winners[movie]"
            :showVotes="isLatestVote(index) && list.vote.winners[movie] > 0"
          />
        </div>
        <IconButton
          v-if="isLatestVote(index) && item.winners.length > 1"
          text="Démarrer un revote"
          iconClass="fa-vote-yea"
          @btnClick="startRevoteForCurrentList"
        />
      </div>
    </div>
    <div v-else class="text error">
      <br /><br />
      L'historique ne contient rien pour le mois sélectionné.
      <br /><br />
    </div>
  </div>
  <IconButton text="Retour à la liste" iconClass="fa-arrow-left" @btnClick="backToList" />
</template>

<script>
import MovieCard from "@/components/MovieCard";
import IconButton from "@/components/IconButton";
import { getListRealtime } from "@/firebase";

export default {
  name: "VoteHistory",
  data() {
    return {
      id: null,
      list: null,
      selectedMonth: null,
    };
  },
  components: {
    MovieCard,
    IconButton,
  },
  methods: {
    getMonths() {
      if (!this.list.history || this.list.history.length === 0) {
        this.selectedMonth = (new Date().getMonth() + 1).toString().padStart(2, "0") + "/" + new Date().getFullYear().toString().padStart(2, "0");
        return [this.selectedMonth];
      }
      const months = [];
      const sortedHistory = this.list.history;
      sortedHistory.sort((a, b) => a.date.localeCompare(b.date));
      const oldestItem = sortedHistory[0];
      const oldestDate = new Date(oldestItem.date);
      let month = oldestDate.getMonth();
      let year = oldestDate.getFullYear();
      months.push((month + 1).toString().padStart(2, "0") + "/" + year.toString().padStart(2, "0"));
      const currentDate = new Date();
      while (month < currentDate.getMonth() || year < currentDate.getFullYear()) {
        if (month === 11) {
          month = 0;
          year++;
        } else {
          month++;
        }
        months.push((month + 1).toString().padStart(2, "0") + "/" + year.toString().padStart(2, "0"));
      }
      months.reverse();
      if (!this.selectedMonth) this.selectedMonth = months[0];
      return months;
    },
    backToList() {
      this.$router.push({ name: "ListDetails", params: { id: this.id } });
    },
    startRevoteForCurrentList() {
      this.$router.push({ name: "StartVote", params: { id: this.id }, query: { revote: true } });
    },
    convertToDateString(isoDate) {
      const date = new Date(isoDate);
      return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
        .getFullYear()
        .toString()
        .padStart(2, "0")} à ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    },
    isLatestVote(index) {
      if (!this.list.history || this.list.history.length === 0) return false;
      const sortedHistory = [...this.list.history];
      sortedHistory.sort((a, b) => b.date.localeCompare(a.date));
      const date = new Date(sortedHistory[0].date);
      const month = parseInt(this.selectedMonth.split("/")[0]);
      const year = parseInt(this.selectedMonth.split("/")[1]);
      return date.getMonth() + 1 === month && date.getFullYear() === year && index === 0;
    },
  },
  computed: {
    filteredHistory() {
      const filteredHistory = this.list.history?.filter((i) => {
        const date = new Date(i.date);
        const month = parseInt(this.selectedMonth.split("/")[0]);
        const year = parseInt(this.selectedMonth.split("/")[1]);
        return date.getMonth() + 1 === month && date.getFullYear() === year;
      });
      if (filteredHistory) filteredHistory.reverse();
      return filteredHistory;
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    const list = getListRealtime(this.id);
    this.list = list;
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .movie-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

#months {
  border: var(--border);
  border-color: var(--green);
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-family: var(--font-family-texts);
  width: 100%;
  background-color: white;
}

.error {
  margin-bottom: 1rem;
  line-height: var(--line-height);
}
</style>
