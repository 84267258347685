<template>
  <div class="card-container text">
    <h3>Informations additionnelles :</h3>
    <p v-if="director">
      <strong>Directeur : </strong> <span class="link" @click="exploreMoviesForDirector(this.director.id)">{{ this.director.name ?? "" }}</span>
    </p>
    <p v-if="originalLanguage"><strong>Langue originale : </strong> {{ this.originalLanguage }}</p>
    <p v-if="originalTitle"><strong>Titre original : </strong> {{ this.originalTitle }}</p>
    <p v-if="currentSummary"><strong>Résumé : </strong> {{ this.currentSummary }}</p>
    <p v-if="!completeSummaryShown && completeSummary">
      <a href="#" @click="showCompleteSummary">Lire la suite +</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "DetailsCard",
  data() {
    return {
      apiKey: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      director: null,
      originalLanguage: "",
      originalTitle: "",
      currentSummary: "",
      completeSummary: "",
      completeSummaryShown: false,
    };
  },
  props: {
    movieId: String,
  },
  methods: {
    showCompleteSummary(e) {
      e.preventDefault();
      this.currentSummary = this.completeSummary;
      this.completeSummaryShown = true;
    },
    exploreMoviesForDirector(directorId) {
      this.$router.push({
        name: "ExploreMovies",
        params: { jobType: "director", personId: directorId },
      });
    },
  },
  async mounted() {
    try {
      // Movie details
      const responseDetails = await fetch(`${this.tmdbUrl}/movie/${this.movieId}?language=fr&api_key=${this.apiKey}`);
      const responseDetailsData = await responseDetails.json();

      this.originalLanguage = responseDetailsData.original_language;
      this.originalTitle = responseDetailsData.original_title;
      this.completeSummary = responseDetailsData.overview;
      this.currentSummary = this.completeSummary.substring(0, 120) + "...";

      // Movie credits
      const responseCredits = await fetch(`${this.tmdbUrl}/movie/${this.movieId}/credits?api_key=${this.apiKey}`);
      const responseCreditsData = await responseCredits.json();

      this.director = responseCreditsData.crew.find((member) => member.job === "Director");
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.card-container {
  line-height: 1.5;
  padding-bottom: 0.25rem;
}

@media (min-width: 1200px) {
  .card-container {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}

h3 {
  line-height: var(--line-height);
  margin-top: 0;
}

p {
  margin: 0 0 0.5rem 0;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--green);
}
</style>
