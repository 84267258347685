<template>
  <div class="card-container text">
    <div class="header-container">
      <h3>Disponibilité :</h3>
      <select v-if="regions.length > 0" name="regions" id="regions" v-model="currentRegion" @change="regionSelected">
        <option v-for="region in regions" :key="region" :value="region">
          {{ region }}
        </option>
      </select>
    </div>
    <div v-if="!movieProviders || movieProviders.length === 0" class="margin-bottom">Aucune plateforme trouvée pour ce film.</div>
    <div class="flex-container">
      <div v-for="provider in movieProvidersForRegion" :key="provider.provider_id" class="col">
        <img :src="tmdbImageUrl + '/' + provider.logo_path" class="provider-logo" />
        {{ provider.provider_name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformsCard",
  data() {
    return {
      apiKey: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      tmdbImageUrl: "https://image.tmdb.org/t/p/w500",
      results: [],
      regions: [],
      currentRegion: null,
      movieProviders: [],
      movieProvidersForRegion: [],
    };
  },
  props: {
    movieId: String,
  },
  methods: {
    regionSelected() {
      // Get all providers for a region
      this.movieProviders = this.results[this.currentRegion];

      if (this.movieProviders?.rent) this.movieProvidersForRegion = this.movieProviders.rent;
      if (this.movieProviders?.buy) this.movieProvidersForRegion = this.movieProvidersForRegion.concat(this.movieProviders.buy);
      if (this.movieProviders?.flatrate) this.movieProvidersForRegion = this.movieProvidersForRegion.concat(this.movieProviders.flatrate);

      // Remove duplicates & sort by priority
      this.movieProvidersForRegion = [...new Map(this.movieProvidersForRegion.map((provider) => [provider["provider_id"], provider])).values()];
      this.movieProvidersForRegion = this.movieProvidersForRegion.sort(function (a, b) {
        return a.display_priority - b.display_priority;
      });
    },
  },
  async mounted() {
    try {
      const response = await fetch(`${this.tmdbUrl}/movie/${this.movieId}/watch/providers?api_key=${this.apiKey}`);
      const responseData = await response.json();

      this.results = responseData.results;

      this.regions = Object.keys(this.results);
      this.currentRegion = this.regions.includes("CA") ? "CA" : this.regions[0];

      this.regionSelected();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.card-container {
  line-height: var(--line-height);
  padding-bottom: 0;
}

@media (min-width: 1200px) {
  .card-container {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}

.margin-bottom {
  margin-bottom: 0.75rem;
}

h3 {
  line-height: var(--line-height);
  margin-top: 0;
}

.flex-container {
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
}

.col {
  text-align: center;
  width: 21%;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
}

.provider-logo {
  width: 100%;
  margin-bottom: 0.2rem;
  border: var(--border);
  border-radius: var(--border-radius);
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

#regions {
  border: var(--border);
  border-color: var(--green);
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-family: var(--font-family-texts);
  padding-left: 0.2rem;
  background-color: white;
}
</style>
