<template>
  <form @submit.prevent="formSubmit">
    <h2 class="title">Créez un nouveau compte</h2>
    <TextInput type="text" v-model="username" placeholder="Nom d'utilisateur" />
    <TextInput type="email" v-model="email" placeholder="Adresse courriel" />
    <TextInput type="password" v-model="password" placeholder="Mot de passe" />
    <TextInput type="password" v-model="passwordConfirmation" placeholder="Confirmation du mot de passe" />
    <IconButton text="Créer mon compte" iconClass="fa-plus" />
    <div v-if="error" class="error text">{{ error }}</div>
    <div class="text">
      Déjà un compte?
      <router-link to="/sign-in">Connectez-vous!</router-link>
    </div>
  </form>
</template>

<script>
import IconButton from "@/components/IconButton";
import TextInput from "@/components/TextInput";
import { getAuth, createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { createUser, checkIfUsernameExists } from "@/firebase";

export default {
  name: "Register",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      error: "",
    };
  },
  components: {
    IconButton,
    TextInput,
  },
  methods: {
    async formSubmit() {
      const auth = getAuth();
      const username = this.username.trim();

      if (!username) {
        this.error = "Veuillez entrer un nom d'utilisateur.";
        return;
      }

      const usernameExists = await checkIfUsernameExists(username);
      if (usernameExists === true) {
        this.error = "Ce nom d'utilisateur est déjà utilisé.";
        return;
      }

      if (this.password !== this.passwordConfirmation) {
        this.error = "Votre mot de passe ne correspond pas à la confirmation.";
        this.password = "";
        this.passwordConfirmation = "";
        return;
      }

      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userInfos) => {
          // User auth created
          const newUser = {
            username: username,
            email: this.email,
          };

          createUser(newUser, userInfos.user.uid)
            .then(() => {
              // User record created
              this.$router.replace({ name: "Home" });
            })
            .catch(() => {
              if (auth.currentUser) deleteUser(auth.currentUser);
              this.error = "Une erreur est survenue. Veuillez vérifier tous les champs et réessayer.";
            });
        })
        .catch((error) => {
          // Descriptive UI errors
          switch (error.code) {
            case "auth/missing-email":
              this.error = "Veuillez entrer un courriel.";
              break;
            case "auth/weak-password":
              this.error = "Votre mot de passe doit avoir au moins 6 caractères.";
              this.password = "";
              this.passwordConfirmation = "";
              break;
            case "auth/email-already-in-use":
              this.error = "Un compte existe déjà avec ce courriel.";
              break;
            case "auth/invalid-email":
              this.error = "Ce courriel n'est pas valide.";
              break;
            default:
              if (auth.currentUser) deleteUser(auth.currentUser);
              this.error = "Une erreur est survenue. Veuillez vérifier tous les champs et réessayer.";
              break;
          }
        });
    },
  },
};
</script>

<style scoped>
.error {
  color: var(--red);
  margin-bottom: 1rem;
  line-height: var(--line-height);
}
</style>
