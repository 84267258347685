<template>
  <h2 class="title">Choisissez une de vos listes...</h2>
  <div class="text" v-if="userLists">
    <div v-for="list in userLists" :key="list.id">
      <ListCard :list="list" :movieId="$route.params.id" :canAdd="true" @card-click="listClick(list)" />
    </div>
  </div>
  <div class="text" v-if="userLists === null">Vous n'avez aucune liste pour le moment.</div>
  <h2 class="title">...ou créez-en une nouvelle</h2>
  <IconButton text="Nouvelle liste" iconClass="fa-plus" @btnClick="newList" />
</template>

<script>
import IconButton from "@/components/IconButton";
import ListCard from "@/components/ListCard";
import { getUserLists, updateList } from "@/firebase";
import { getAuth } from "firebase/auth";

export default {
  name: "AddToList",
  data() {
    return {
      userLists: null,
    };
  },
  components: {
    IconButton,
    ListCard,
  },
  methods: {
    newList() {
      this.$router.push({ name: "ListCreate" });
    },
    listClick(list) {
      const movieId = this.$route.params.id;

      if (!list.movies.includes(movieId) && !list.vote.ongoing) {
        list.movies.push(movieId);
        updateList(list);
      }
    },
  },
  mounted() {
    const auth = getAuth();
    const user = auth.currentUser;
    this.userLists = getUserLists(user.uid);
  },
};
</script>
