<template>
  <div class="card-container text">
    <h3>Acteurs principaux :</h3>
    <div v-if="!actors || actors.length === 0">Aucun acteur trouvé pour ce film.</div>
    <div class="flex-container">
      <div v-for="actor in actors" :key="actor.id" class="col" @click="exploreMoviesForActor(actor.id)">
        <img v-if="actor.profile_path" :src="tmdbImageUrl + '/' + actor.profile_path" class="actor-image" />
        <img v-else src="@/assets/ImageNotFound.jpg" class="actor-image" />
        {{ actor.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CastCard",
  data() {
    return {
      apiKey: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      tmdbImageUrl: "https://image.tmdb.org/t/p/w342",
      actors: "",
    };
  },
  props: {
    movieId: String,
  },
  methods: {
    exploreMoviesForActor(actorId) {
      this.$router.push({
        name: "ExploreMovies",
        params: { jobType: "actor", personId: actorId },
      });
    },
  },
  async mounted() {
    try {
      const response = await fetch(`${this.tmdbUrl}/movie/${this.movieId}/credits?api_key=${this.apiKey}`);
      const responseData = await response.json();

      this.actors = responseData.cast.slice(0, 3);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.card-container {
  line-height: var(--line-height);
}

@media (min-width: 1200px) {
  .card-container {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}

h3 {
  margin-top: 0;
}

.flex-container {
  display: flex;
  gap: 5%;
}

.col {
  text-align: center;
  width: 30%;
  cursor: pointer;
}

.actor-image {
  width: 100%;
  border-radius: var(--border-radius);
  margin-bottom: 0.2rem;
}
</style>
