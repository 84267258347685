<template>
  <h2 class="title">Votre compte</h2>
  <p class="text"><strong>Nom d'utilisateur : </strong>{{ this.username }}</p>
  <p class="text">
    <strong>Adresse courriel : </strong>
    <span class="email">{{ this.email }}</span>
  </p>
  <br />
  <IconButton text="Me déconnecter" iconClass="fa-sign-out-alt" @btnClick="signOut" />
</template>

<script>
import IconButton from "@/components/IconButton";
import { getAuth, signOut } from "firebase/auth";
import { getUser } from "@/firebase";

export default {
  name: "Profile",
  data() {
    return {
      username: null,
      email: null,
    };
  },
  components: {
    IconButton,
  },
  methods: {
    signOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.replace({ name: "SignIn" });
      });
    },
  },
  async mounted() {
    const auth = getAuth();
    const user = auth.currentUser;
    const userInfos = await getUser(user.uid);
    this.username = userInfos.username;
    this.email = userInfos.email;
  },
};
</script>

<style scoped>
.text {
  line-height: var(--line-height);
}

.email {
  text-decoration: underline;
}
</style>
