<template>
  <div v-if="list">
    <h2 class="title">Démarrer un vote — {{ this.list.name }}</h2>
    <h3 class="title">Nombre de votes : {{ this.nbVotes }}</h3>
    <input class="slider" type="range" min="1" max="5" v-model="nbVotes" />
    <br /><br />
    <IconButton text="Démarrer le vote!" iconClass="fa-vote-yea" @btnClick="startVote" />
    <IconButton text="Annuler" iconClass="fa-times" bgColor="#b83939" @btnClick="cancel" />
    <div v-if="this.moviesList && this.moviesList.length <= this.nbVotes" class="error text">
      Le nombre de votes doit être inférieur au nombre de films dans la liste.
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/IconButton";
import { getListRealtime, updateList } from "@/firebase";

export default {
  name: "ListDetails",
  data() {
    return {
      id: null,
      list: null,
      nbVotes: 1,
    };
  },
  components: {
    IconButton,
  },
  methods: {
    startVote() {
      if (this.moviesList.length > this.nbVotes) {
        this.list.vote.ongoing = true;
        this.list.vote.revote = this.$route.query.revote == "true";
        this.list.vote.nbVotes = parseInt(this.nbVotes);
        updateList(this.list);
        this.$router.replace({
          name: "ListDetails",
          params: { id: this.id },
        });
      }
    },
    cancel() {
      this.$router.replace({
        name: "ListDetails",
        params: { id: this.id },
      });
    },
  },
  computed: {
    lastWinners() {
      if (!this.list.history || this.list.history.length === 0) return [];
      const sortedHistory = [...this.list.history];
      sortedHistory.sort((a, b) => b.date.localeCompare(a.date));
      return sortedHistory[0].winners ?? [];
    },
    moviesList() {
      return this.$route.query.revote == "true" ? this.lastWinners : this.list.movies;
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    const list = getListRealtime(this.id);
    this.list = list;
  },
};
</script>

<style scoped>
.slider {
  appearance: none;
  width: 100%;
  height: 20px;
  border-radius: var(--border-radius);
  border: var(--border);
  box-sizing: border-box;
}
.slider::-webkit-slider-thumb {
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--green);
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--green);
  cursor: pointer;
}

.error {
  color: var(--red);
}
</style>
