<template>
  <div class="progress-bar-container">
    <div class="progress-bar-content" :style="{ width: `${(this.current / this.total) * 100}%` }"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    current: Number,
    total: Number,
  },
};
</script>

<style scoped>
.progress-bar-container {
  position: relative;
  height: 10px;
  border-radius: var(--border-radius);
  border: var(--border);
  overflow: hidden;
}

.progress-bar-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--green);
  border-radius: var(--border-radius);
}
</style>
