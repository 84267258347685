<template>
  <h2 class="title">Détails du film</h2>
  <div class="cards-container">
    <MovieCard :movieId="$route.params.movieId" />
    <DetailsCard :movieId="$route.params.movieId" />
    <RatingsCard :movieId="$route.params.movieId" />
    <CastCard :movieId="$route.params.movieId" />
    <PlatformsCard :movieId="$route.params.movieId" />
    <TrailerCard :movieId="$route.params.movieId" />
  </div>
  <IconButton text="Ajouter à une liste" iconClass="fa-plus" @btnClick="addToList" />
  <IconButton
    v-if="$route.params.listId !== 'no-list' && list != null && !list.vote.ongoing"
    text="Retirer de la liste"
    iconClass="fa-trash"
    bgColor="#b83939"
    @btnClick="removeFromlist"
  />
  <ArrowDown />
</template>

<script>
import MovieCard from "@/components/MovieCard";
import DetailsCard from "@/components/DetailsCard";
import RatingsCard from "@/components/RatingsCard";
import CastCard from "@/components/CastCard";
import PlatformsCard from "@/components/PlatformsCard";
import TrailerCard from "@/components/TrailerCard";
import IconButton from "@/components/IconButton";
import ArrowDown from "@/components/ArrowDown";
import { getList, updateList } from "@/firebase";

export default {
  name: "MovieDetails",
  data() {
    return {
      list: null,
    };
  },
  components: {
    MovieCard,
    DetailsCard,
    RatingsCard,
    CastCard,
    PlatformsCard,
    TrailerCard,
    IconButton,
    ArrowDown,
  },
  methods: {
    addToList() {
      this.$router.push({
        name: "AddToList",
        params: { id: this.$route.params.movieId },
      });
    },
    async removeFromlist() {
      const movieId = this.$route.params.movieId;

      const idx = this.list.movies.indexOf(movieId);
      if (idx > -1) {
        this.list.movies.splice(idx, 1);
        updateList(this.list);
        this.$router.replace({
          name: "ListDetails",
          params: { id: this.$route.params.listId },
        });
      }
    },
  },
  async mounted() {
    const listId = this.$route.params.listId;
    if (listId !== "no-list") this.list = await getList(listId);
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}
</style>
