<template>
  <button @click="btnClick" :style="style">{{ text }} <i class="fas" :class="iconClass"></i></button>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    text: {
      type: String,
      default: "Button",
    },
    bgColor: String,
    fgColor: String,
    iconClass: String,
  },
  methods: {
    btnClick() {
      this.$emit("btn-click");
    },
  },
  computed: {
    style() {
      return {
        "background-color": this.bgColor,
        "border-color": this.bgColor,
        color: this.fgColor,
      };
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: var(--font-family-texts);
  margin-bottom: 1rem;
  color: white;
  background-color: var(--green);
  border: 2px solid var(--green);
  border-radius: var(--border-radius);
  text-align: left;
  transition-duration: var(--transition-duration);
  cursor: pointer;
}

button:hover {
  filter: brightness(140%);
}

button > .fas {
  float: right;
  padding-top: 0.4rem;
  padding-right: 0.1rem;
  width: 1.2rem;
  text-align: center;
}
</style>
