<template>
  <div v-if="list">
    <h2 class="title">{{ this.list.name }}</h2>
    <div v-if="list.movies != null && list.movies.length > 1">
      <div v-if="list && list.vote.ongoing">
        <h3 class="title">Un vote est en cours!</h3>
        <p class="text">
          Personnes ayant voté :
          {{ this.numberOfVotes }} / {{ this.list.members.length }}
        </p>
        <ProgressBar :current="numberOfVotes" :total="this.list.members.length" />
        <br /><br />
        <IconButton :text="hasVoted ? 'Modifier mon vote' : 'Voter'" iconClass="fa-vote-yea" @btnClick="voteForCurrentList" />
        <IconButton text="Mettre fin au vote" bgColor="#b83939" iconClass="fa-ban" @btnClick="endVoteForCurrentList" />
      </div>
      <div v-else>
        <h3 class="title">Aucun vote en cours pour le moment</h3>
        <IconButton text="Démarrer un vote" iconClass="fa-vote-yea" @btnClick="startVoteForCurrentList" />
      </div>
    </div>
    <IconButton text="Voir l'historique des résultats" iconClass="fa-poll" @btnClick="seeResultsForCurrentList" />
    <br /><br />
    <div v-if="list.movies != null && list.movies.length > 0" class="movie-cards-container">
      <MovieCard
        v-for="movie in list.movies"
        :key="movie"
        :movieId="movie"
        :listId="id"
        :votes="numberOfVotesForMovie(movie)"
        :showVotes="list.vote.ongoing"
      />
    </div>
    <div v-else class="text error">
      Cette liste ne contient aucun film pour le moment.
      <br /><br />
    </div>
  </div>
  <div v-if="isAdmin">
    <IconButton text="Modifier la liste" iconClass="fa-edit" @btnClick="editCurrentList" />
  </div>
  <div v-if="isOwner">
    <IconButton text="Supprimer la liste" bgColor="#b83939" iconClass="fa-trash" @btnClick="deleteCurrentList" />
  </div>
  <div v-if="!isOwner">
    <IconButton text="Quitter la liste" bgColor="#b83939" iconClass="fa-times" @btnClick="leaveCurrentList" />
  </div>
  <ArrowDown />
</template>

<script>
import MovieCard from "@/components/MovieCard";
import IconButton from "@/components/IconButton";
import ArrowDown from "@/components/ArrowDown";
import ProgressBar from "@/components/ProgressBar";
import { getListRealtime, deleteList, isUserAdminInList, updateList, deleteInvitesForList } from "@/firebase";
import { getAuth } from "firebase/auth";

export default {
  name: "ListDetails",
  data() {
    return {
      id: null,
      list: null,
      isAdmin: false,
      isOwner: false,
      hasVoted: false,
    };
  },
  components: {
    MovieCard,
    IconButton,
    ArrowDown,
    ProgressBar,
  },
  computed: {
    numberOfVotes() {
      return Object.keys(this.list.vote.votes).length;
    },
    allVotes() {
      let allVotes = [];
      Object.keys(this.list.vote.votes).forEach((userId) => {
        allVotes = allVotes.concat(this.list.vote.votes[userId]);
      });
      return allVotes;
    },
  },
  methods: {
    voteForCurrentList() {
      this.$router.push({
        name: "ListVote",
        params: { id: this.id },
      });
    },
    startVoteForCurrentList() {
      this.$router.push({ name: "StartVote", params: { id: this.id }, query: { revote: false } });
    },
    endVoteForCurrentList() {
      if (this.numberOfVotes < this.list.members.length) {
        if (!confirm("Il y a encore des membres qui n'ont pas voté. Voulez-vous tout de même mettre fin au vote?")) return;
      } else {
        if (!confirm("Voulez-vous vraiment mettre fin au vote?")) return;
      }

      // Determine the winner(s)
      let frequency = {};
      this.allVotes.forEach((vote) => {
        frequency[vote] = 0;
      });

      let uniques = this.allVotes.filter((vote) => {
        return ++frequency[vote] === 1;
      });

      const sortedVotes = uniques.sort((a, b) => {
        return frequency[b] - frequency[a];
      });

      let winners = {};
      Object.keys(frequency).forEach((vote) => {
        if (frequency[vote] === frequency[sortedVotes[0]]) {
          winners[vote] = frequency[vote];
        }
      });

      this.list.vote.ongoing = false;
      this.list.vote.votes = {};
      this.list.vote.winners = winners;
      if (!this.list.history) this.list.history = [];
      if (Object.keys(winners).length !== 0) this.list.history.push({ date: new Date().toISOString(), winners: Object.keys(winners) });
      updateList(this.list);
    },
    editCurrentList() {
      this.$router.push({ name: "ListEdit", params: { id: this.id } });
    },
    async deleteCurrentList() {
      if (confirm("Voulez-vous vraiment supprimer cette liste?")) {
        await deleteInvitesForList(this.id);
        deleteList(this.id);
        this.$router.replace({ name: "Home" });
      }
    },
    leaveCurrentList() {
      if (confirm("Voulez-vous vraiment quitter cette liste?")) {
        const auth = getAuth();
        const user = auth.currentUser;
        const updatedList = this.list;

        if (user) {
          const memberIndex = updatedList.members.indexOf(user.uid);
          if (memberIndex > -1) {
            updatedList.members.splice(memberIndex, 1);
          }

          const adminIndex = updatedList.administrators.indexOf(user.uid);
          if (adminIndex > -1) {
            updatedList.administrators.splice(adminIndex, 1);
          }
        }

        updateList(updatedList);
        this.$router.replace({ name: "Home" });
      }
    },
    numberOfVotesForMovie(movieId) {
      return this.allVotes.filter((vote) => vote === movieId).length;
    },
    seeResultsForCurrentList() {
      this.$router.push({
        name: "VoteHistory",
        params: { id: this.id },
      });
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    const list = getListRealtime(this.id);
    this.list = list;

    const auth = getAuth();
    const user = auth.currentUser;

    const isAdmin = await isUserAdminInList(user.uid, this.id);
    this.isAdmin = isAdmin;

    const isOwner = this.list.owner === user.uid;
    this.isOwner = isOwner;

    this.hasVoted = this.list.vote.votes[user.uid] != null;
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .movie-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.error {
  margin-bottom: 1rem;
  line-height: var(--line-height);
}
</style>
