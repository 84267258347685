<template>
  <div id="header-container">
    <img id="logo" @click="logoClick" src="@/assets/MovieVoteLogo.png" alt="Logo" />
  </div>
  <div id="empty"></div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    logoClick() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
#header-container {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1.15rem 0.75rem 0.75rem 0.75rem;
  background-color: var(--light-grey);
  text-align: center;
  z-index: 1;
  border-bottom: var(--border);
}

#logo {
  height: 2rem;
  cursor: pointer;
}

#empty {
  height: 3.4rem;
}
</style>
