<template>
  <div class="card-container text">
    <h3>Notes du film :</h3>
    <div v-if="!imdbRating && !tmdbRating && !rottenTomatoesRating && !tmdbRating">Aucune note trouvée pour ce film.</div>
    <div class="flex-container flex-container-logos">
      <div v-if="imdbRating" class="col">
        <img src="@/assets/IMDB_logo.png" class="rating-logo" />
      </div>
      <div v-if="tmdbRating" class="col">
        <img src="@/assets/TMDB_logo.svg" class="rating-logo" />
      </div>
      <div v-if="rottenTomatoesRating" class="col">
        <img src="@/assets/RottenTomatoes_logo.png" class="rating-logo rounded-logo" />
      </div>
      <div v-if="metacriticRating" class="col">
        <img src="@/assets/Metacritic_logo.png" class="rating-logo" />
      </div>
    </div>
    <div class="flex-container">
      <div v-if="imdbRating" class="col">
        <strong>{{ imdbRating }}</strong>
        <p>Internet Movie Database</p>
      </div>
      <div v-if="tmdbRating" class="col">
        <strong>{{ tmdbRating }}/10</strong>
        <p>The Movie Database</p>
      </div>
      <div v-if="rottenTomatoesRating" class="col">
        <strong>{{ rottenTomatoesRating }}</strong>
        <p>Rotten Tomatoes</p>
      </div>
      <div v-if="metacriticRating" class="col">
        <strong>{{ metacriticRating }}</strong>
        <p>Metacritic</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingsCard",
  data() {
    return {
      apiKeyTmdb: "c63233f1cfef82958214ca7ba2d65921",
      tmdbUrl: "https://api.themoviedb.org/3",
      apiKeyOmdb: "f78ba769",
      omdbUrl: "https://www.omdbapi.com",
      imdbRating: null,
      tmdbRating: null,
      rottenTomatoesRating: null,
      metacriticRating: null,
    };
  },
  props: {
    movieId: String,
  },
  async mounted() {
    try {
      // TMDB rating
      const responseTMDB = await fetch(`${this.tmdbUrl}/movie/${this.movieId}?language=fr&api_key=${this.apiKeyTmdb}`);
      const responseTMDBData = await responseTMDB.json();

      this.tmdbRating = responseTMDBData.vote_average;

      // IMDB rating, Rotten Tomatoes rating & Metacritic rating
      const responseOMDB = await fetch(`${this.omdbUrl}/?i=${responseTMDBData.imdb_id}&apikey=${this.apiKeyOmdb}`);
      const responseOMDBData = await responseOMDB.json();

      if (responseOMDBData.Ratings !== null) {
        this.imdbRating = responseOMDBData.Ratings.find((rating) => rating.Source === "Internet Movie Database")?.Value;
        this.rottenTomatoesRating = responseOMDBData.Ratings.find((rating) => rating.Source === "Rotten Tomatoes")?.Value;
        this.metacriticRating = responseOMDBData.Ratings.find((rating) => rating.Source === "Metacritic")?.Value;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.card-container {
  line-height: var(--line-height);
}

@media (min-width: 1200px) {
  .card-container {
    width: calc(50% - 0.5rem);
    margin: 0;
  }
}

h3 {
  margin-top: 0;
}

.flex-container {
  display: flex;
  gap: 5.33%;
}

.flex-container-logos {
  align-items: center;
}

.col {
  text-align: center;
  width: 21%;
}

.rating-logo {
  width: 100%;
  margin-bottom: 0.5rem;
}

.rounded-logo {
  border-radius: var(--border-radius);
}

p {
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 0.9rem;
}
</style>
