<template>
  <div class="container">
    <h2 v-if="id" class="title">Modifiez une liste</h2>
    <h2 v-else class="title">Créez une nouvelle liste</h2>

    <h3 class="title">Détails de la liste</h3>
    <TextInput type="text" v-model="listName" placeholder="Entrez le nom de la liste" />

    <h3 v-if="id" class="title">Ajoutez ou retirez des membres</h3>
    <h3 v-else class="title">Ajoutez des membres</h3>
    <div class="text" v-if="members.length > 0">
      <strong>Membres : </strong>
      <div v-for="member in members" :key="member.key">
        <div class="user">
          <div>{{ member.value }}</div>
          <div class="remove-user-btn" @click="removeMember(member.key)">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="text" v-if="invitedMembers.length > 0">
      <strong>Membres invités : </strong>
      <div v-for="member in invitedMembers" :key="member.key">
        <div class="user">
          <div>{{ member.value }}</div>
          <div class="remove-user-btn" @click="removeInvitedMember(member.key)">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <br />
    </div>
    <TextInput type="text" v-model="memberUsernameOrEmail" placeholder="Entrez un courriel / nom d'utilisateur" />
    <IconButton text="Inviter à rejoindre la liste" iconClass="fa-envelope" bgColor="#6b818c" @btnClick="addMember" />
    <div v-if="errorMembers" class="error text">{{ errorMembers }}</div>

    <h3 v-if="id" class="title">Ajoutez ou retirez des administateurs</h3>
    <h3 v-else class="title">Ajoutez des administateurs</h3>
    <div class="text" v-if="administrators.length > 0">
      <strong>Administrateurs : </strong>
      <div v-for="administrator in administrators" :key="administrator.key">
        <div class="user">
          <div>{{ administrator.value }}</div>
          <div class="remove-user-btn" @click="removeAdministrator(administrator.key)">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="text" v-if="invitedAdministrators.length > 0">
      <strong>Administrateurs invités : </strong>
      <div v-for="administrator in invitedAdministrators" :key="administrator.key">
        <div class="user">
          <div>{{ administrator.value }}</div>
          <div class="remove-user-btn" @click="removeInvitedAdministrator(administrator.key)">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <br />
    </div>
    <TextInput type="text" v-model="administratorUsernameOrEmail" placeholder="Entrez un courriel / nom d'utilisateur" />
    <IconButton text="Inviter à rejoindre la liste" iconClass="fa-envelope" bgColor="#6b818c" @btnClick="addAdministrator" />
    <div v-if="errorAdministrators" class="error text">
      {{ errorAdministrators }}
    </div>

    <br /><br />
    <IconButton
      :text="id ? 'Sauvegarder mes modifications' : 'Créer ma liste'"
      :iconClass="id ? 'fa-check' : 'fa-plus'"
      @btnClick="confirmBtnClick"
    />
    <div v-if="error" class="error text">{{ error }}</div>
    <IconButton text="Annuler" iconClass="fa-times" bgColor="#b83939" @btnClick="cancel" />
  </div>
</template>

<script>
import TextInput from "@/components/TextInput";
import IconButton from "@/components/IconButton";
import { getAuth } from "firebase/auth";
import { createList, getUserByEmail, getUserByUsername, createInvite, getList, getUser, updateList, deleteInvitesForUserForList } from "@/firebase";

export default {
  name: "ListCreateOrEdit",
  data() {
    return {
      listName: "",
      error: "",
      memberUsernameOrEmail: "",
      members: [],
      errorMembers: "",
      administratorUsernameOrEmail: "",
      administrators: [],
      errorAdministrators: "",
      invitedMembers: [],
      invitedAdministrators: [],
      userInvitesToDelete: [],
      id: null,
      list: null,
    };
  },
  components: {
    TextInput,
    IconButton,
  },
  methods: {
    async addMember() {
      const userInfosFromUsername = await getUserByUsername(this.memberUsernameOrEmail);

      let uid = null;
      let username = null;

      if (userInfosFromUsername) {
        uid = userInfosFromUsername.id;
        username = userInfosFromUsername.username;
      } else {
        const userInfosFromEmail = await getUserByEmail(this.memberUsernameOrEmail);

        if (userInfosFromEmail) {
          uid = userInfosFromEmail.id;
          username = userInfosFromEmail.username;
        }
      }

      if (!uid) {
        this.errorMembers = "Aucun compte n'a été trouvé avec ce courriel / nom d'utilisateur.";
        return;
      }

      if (this.members.find((m) => m.key === uid) || this.invitedMembers.find((m) => m.key === uid)) {
        this.errorMembers = "Ce compte a déjà été ajouté à la liste.";
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;
      if (uid === user.uid) {
        this.errorMembers = "Vous n'avez pas besoin de vous ajouter à votre propre liste.";
        return;
      }

      this.removeAdministrator(uid);
      this.removeInvitedAdministrator(uid);
      this.removeMember(uid);
      this.removeInvitedMember(uid);
      this.invitedMembers.push({ key: uid, value: username });
      this.memberUsernameOrEmail = "";
      this.errorMembers = "";
    },
    removeMember(uid) {
      let idx = this.members.findIndex((m) => m.key === uid);
      if (idx > -1) this.members.splice(idx, 1);

      if (this.id) {
        let idx2 = this.list.members.findIndex((m) => m === uid);
        if (idx2 > -1) this.list.members.splice(idx2, 1);
      }
    },
    removeInvitedMember(uid) {
      let idx = this.invitedMembers.findIndex((m) => m.key === uid);
      if (idx > -1) {
        this.invitedMembers.splice(idx, 1);
        if (!this.userInvitesToDelete.includes(uid)) this.userInvitesToDelete.push(uid);
      }

      if (this.id) {
        let idx2 = this.list.invitedMembers.findIndex((m) => m === uid);
        if (idx2 > -1) {
          this.list.invitedMembers.splice(idx2, 1);
          if (!this.userInvitesToDelete.includes(uid)) this.userInvitesToDelete.push(uid);
        }
      }
    },
    async addAdministrator() {
      const userInfosFromUsername = await getUserByUsername(this.administratorUsernameOrEmail);

      let uid = null;
      let username = null;

      if (userInfosFromUsername) {
        uid = userInfosFromUsername.id;
        username = userInfosFromUsername.username;
      } else {
        const userInfosFromEmail = await getUserByEmail(this.administratorUsernameOrEmail);

        if (userInfosFromEmail) {
          uid = userInfosFromEmail.id;
          username = userInfosFromEmail.username;
        }
      }

      if (!uid) {
        this.errorAdministrators = "Aucun compte n'a été trouvé avec ce courriel / nom d'utilisateur.";
        return;
      }

      if (this.administrators.find((m) => m.key === uid) || this.invitedAdministrators.find((m) => m.key === uid)) {
        this.errorAdministrators = "Ce compte a déjà été ajouté à la liste.";
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;
      if (uid === user.uid) {
        this.errorAdministrators = "Vous n'avez pas besoin de vous ajouter à votre propre liste.";
        return;
      }

      this.removeAdministrator(uid);
      this.removeInvitedAdministrator(uid);
      this.removeMember(uid);
      this.removeInvitedMember(uid);
      this.invitedAdministrators.push({ key: uid, value: username });
      this.administratorUsernameOrEmail = "";
      this.errorAdministrators = "";
    },
    removeAdministrator(uid) {
      let idx = this.administrators.findIndex((a) => a.key === uid);
      if (idx > -1) this.administrators.splice(idx, 1);

      if (this.id) {
        let idx2 = this.list.administrators.findIndex((a) => a === uid);
        if (idx2 > -1) this.list.administrators.splice(idx2, 1);
      }
    },
    removeInvitedAdministrator(uid) {
      let idx = this.invitedAdministrators.findIndex((a) => a.key === uid);
      if (idx > -1) {
        this.invitedAdministrators.splice(idx, 1);
        if (!this.userInvitesToDelete.includes(uid)) this.userInvitesToDelete.push(uid);
      }

      if (this.id) {
        let idx2 = this.list.invitedAdministrators.findIndex((a) => a === uid);
        if (idx2 > -1) {
          this.list.invitedAdministrators.splice(idx2, 1);
          if (!this.userInvitesToDelete.includes(uid)) this.userInvitesToDelete.push(uid);
        }
      }
    },
    async createList() {
      const auth = getAuth();
      const user = auth.currentUser;

      // Creating the list
      const newList = {
        name: this.listName,
        owner: user.uid,
        administrators: [user.uid],
        members: [user.uid],
        invitedMembers: [],
        invitedAdministrators: [],
        movies: [],
        vote: {
          ongoing: false,
          nbVotes: 1,
          votes: {},
        },
        history: [],
      };

      if (this.listName) {
        const listInfos = await createList(newList);
        const list = await getList(listInfos.id);
        this.list = list;

        // Sending invites
        for (const idx in this.invitedMembers) {
          const newInvite = {
            to: this.invitedMembers[idx].key,
            from: user.uid,
            list: listInfos.id,
            type: "member",
          };

          createInvite(newInvite);
          this.list.invitedMembers = this.list.invitedMembers
            ? [...this.list.invitedMembers, this.invitedMembers[idx].key]
            : [this.invitedMembers[idx].key];
        }

        for (const idx in this.invitedAdministrators) {
          const newInvite = {
            to: this.invitedAdministrators[idx].key,
            from: user.uid,
            list: listInfos.id,
            type: "administrator",
          };

          createInvite(newInvite);
          this.list.invitedAdministrators = this.list.invitedAdministrators
            ? [...this.list.invitedAdministrators, this.invitedAdministrators[idx].key]
            : [this.invitedAdministrators[idx].key];
        }

        this.list.id = listInfos.id;
        updateList(this.list);

        this.$router.replace({ name: "Home" });
      } else this.error = "Veuillez entrer un nom pour la liste.";
    },
    async editList() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (this.listName) {
        // Deleting expired invites
        for (const idx in this.userInvitesToDelete) await deleteInvitesForUserForList(this.userInvitesToDelete[idx], this.list.id);

        // Sending invites
        for (const idx in this.invitedMembers) {
          const uidMember = this.invitedMembers[idx].key;
          if (!this.list.invitedMembers.includes(uidMember) && this.list.owner !== uidMember) {
            const newInvite = {
              to: uidMember,
              from: user.uid,
              list: this.id,
              type: "member",
            };

            createInvite(newInvite);
            this.list.invitedMembers = this.list.invitedMembers ? [...this.list.invitedMembers, uidMember] : [uidMember];
          }
        }

        for (const idx in this.invitedAdministrators) {
          const uidAdmin = this.invitedAdministrators[idx].key;
          if (!this.list.invitedAdministrators.includes(uidAdmin) && this.list.owner !== uidAdmin) {
            const newInvite = {
              to: uidAdmin,
              from: user.uid,
              list: this.id,
              type: "administrator",
            };

            createInvite(newInvite);
            this.list.invitedAdministrators = this.list.invitedAdministrators ? [...this.list.invitedAdministrators, uidAdmin] : [uidAdmin];
          }
        }

        // Updating the list
        this.list.name = this.listName;
        updateList(this.list);

        this.$router.replace({
          name: "ListDetails",
          params: { id: this.id },
        });
      } else this.error = "Veuillez entrer un nom pour la liste.";
    },
    confirmBtnClick() {
      if (this.id) {
        this.editList();
      } else {
        this.createList();
      }
    },
    cancel() {
      if (this.id) {
        this.$router.replace({
          name: "ListDetails",
          params: { id: this.id },
        });
      } else {
        this.$router.replace({ name: "Home" });
      }
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    if (this.id != null) {
      const list = await getList(this.id);
      this.list = list;

      // Default values if editing a list
      if (this.list != null) {
        this.listName = this.list.name;

        for (const administrator of this.list.administrators) {
          if (administrator !== this.list.owner) {
            const user = await getUser(administrator);
            this.administrators.push({
              key: administrator,
              value: user.username,
            });
          }
        }

        for (const member of this.list.members) {
          if (member !== this.list.owner) {
            if (!this.administrators.map((a) => a.key).includes(member)) {
              const user = await getUser(member);
              this.members.push({
                key: member,
                value: user.username,
              });
            }
          }
        }

        for (const invitedMember of this.list.invitedMembers) {
          const user = await getUser(invitedMember);
          this.invitedMembers.push({
            key: invitedMember,
            value: user.username,
          });
        }

        for (const invitedAdministrator of this.list.invitedAdministrators) {
          const user = await getUser(invitedAdministrator);
          this.invitedAdministrators.push({
            key: invitedAdministrator,
            value: user.username,
          });
        }
      }
    }
  },
};
</script>

<style scoped>
.container {
  line-height: var(--line-height);
}

.error {
  color: var(--red);
}

.user {
  display: flex;
  justify-content: space-between;
}

.remove-user-btn {
  font-size: 1.2rem;
  color: var(--red);
  cursor: pointer;
}
</style>
